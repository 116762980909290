body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.score-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
}
.score-container {
  text-align: center;
}
:root {
  --score-color: #32bbbb;
  --team-names-color: #5cc4cf;
  --background-color: #000000;
}

.score-display {
  background-color: var(--background-color);
}

.score {
  font-size: 25vw;
  font-weight: bold;
  color: var(--score-color);
  line-height: 1;
}

.team-names {
  font-size: 6vw;
  margin-top: 20px;
  color: var(--team-names-color);
}